<template>
  <div class="bigcontext" v-loading="loading">
    <div class="flex">
      <div class="df-item">材料配置选择</div>
    </div>
    <el-divider style="margin:12px 0 24px;"></el-divider>
    <div class="mcontext">
      <el-form ref="form" :model="page_data" label-position="left" label-width="120px">
        <el-form-item label="材料名称" :rules="rules.notNull" prop="formName">
          <el-input v-model="page_data.formName" size="mini" @input="forceUpdate" placeholder="请输入材料名称"></el-input>
        </el-form-item>
        <el-form-item :prop="`configList.title`" :rules="rules.notNull" :label="`${page_data.configList.name}标题`">
          <el-input size="mini" v-model="page_data.configList.title" placeholder="请输入标题" @input="forceUpdate">
          </el-input>
        </el-form-item>
        <el-form-item :prop="`configList.name`" :rules="rules.notNull" label="选择组件">
          <el-select size="mini" v-model="page_data.configList.name" @change="select">
            <el-option v-for="(option,opindex) in typeList" :value="opindex+','+index" :label="option.name"
              :key="index+'option'+opindex"></el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="是否必填"
                      :prop="`configList.required`">
          <el-tooltip class="item"
                      effect="light"
                      content="是否是必填项"
                      placement="top">
            <el-switch size="mini"
                       class="m-l-10 m-r-10"
                       v-model="page_data.configList.required"
                       :active-value="1"
                       :inactive-value="0"
                       @click="$forceUpdate">
            </el-switch>
          </el-tooltip>
        </el-form-item> -->

        <!-- 输入框 -->
        <div v-if="page_data.configList.type=='input'">
          <!-- <el-form-item label="占位文本"
                          :prop="`configList.${index}.placeholder`">
              <el-tooltip class="item"
                          effect="light"
                          content="默认为请输入+标题名"
                          placement="top">
                <el-input placeholder="请输入占位文本"
                          size="mini"
                          @input="forceUpdate"
                          v-model="item.placeholder">
                </el-input>
              </el-tooltip>
            </el-form-item> -->
          <el-form-item label="文本长度" :prop="`configList.textLength`">
            <el-tooltip class="item" effect="light" content="限制长度,0为不限制" placement="top">
              <el-input-number size="mini" :min="0" :max="Infinity" step-strictly controls-position="right"
                @change="forceUpdate" v-model="page_data.configList.textLength">
              </el-input-number>
            </el-tooltip>
          </el-form-item>
          <el-form-item label="校验规则">
            <el-tooltip class="item" effect="light" placement=" top-end">
              <template #content>填写添加正则校验,不填则不加.<br />转义符请输入两次 例:\d=>\\d</template>
              <el-input size="mini" placeholder="请输入正则校验" v-model="page_data.configList.pattern" @input="patternUpdate">
              </el-input>
            </el-tooltip>
          </el-form-item>
          <el-form-item label="常用规则">
            <el-select v-model="page_data.configList.patternName" size="mini" filterable allow-create
              default-first-option @change="patternNameUpdate" placeholder="请选择常用正则">
              <el-option v-for="item in usefullPattern" :key="item.name" :label="item.name" :value="item.pattern">
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <!-- 文字域 -->
        <div v-else-if="page_data.configList.type=='textarea'">
          <!-- <el-form-item label="占位文本">
              <el-tooltip class="item"
                          effect="light"
                          content="默认为请输入+标题名"
                          placement="top">
                <el-input placeholder="请输入占位文本"
                          size="mini"
                          @input="forceUpdate"
                          v-model="item.placeholder">
                </el-input>
              </el-tooltip>
            </el-form-item> -->
        </div>
        <!-- 单选 -->
        <div v-else-if="page_data.configList.type=='radio'||page_data.configList.type=='industry'">
          <div v-for="(radioItem,radioIndex) in page_data.configList.items" :key="index+'radio'+radioIndex">
            <el-form-item :prop="`configList.items.${radioIndex}.name`" :rules="rules.notNull"
              :label="`选项${radioIndex+1}`">
              <div class="flex-center">
                <el-input placeholder="请输入选项" size="mini" v-model="radioItem.name" @input="forceUpdate">
                </el-input>
                <el-button icon="el-icon-close" class="m-l-5" type=" success" plain size="mini" circle
                  @click="delItemsItem(index,radioIndex)" v-show="page_data.configList.items.length>2"></el-button>
              </div>
            </el-form-item>
          </div>
          <el-button size="mini" icon="el-icon-plus" circle @click="appendItemsItem(index)">
          </el-button>
        </div>
        <!-- 多选 -->
        <div v-else-if="page_data.configList.type=='checkbox'">
          <el-form-item :prop="`configList.mulite`" :rules="rules.notNull" :label="`可选数量`">
            <el-tooltip class="item" effect="light" content="可选数量,至少一个" placement="top">
              <el-input-number class="" :min="1" :step="1" :max="page_data.configList.items.length"
                v-model="page_data.configList.mulite" size="mini" @change="forceUpdate"></el-input-number>
            </el-tooltip>
          </el-form-item>

          <div>
            <div v-for="(radioItem,radioIndex) in page_data.configList.items" :key="index+'radio'+radioIndex">
              <el-form-item :prop="`configList.items.${radioIndex}.name`" :rules="rules.notNull"
                :label="`选项${radioIndex+1}`">
                <div class="flex-center">
                  <el-input placeholder="请输入选项名称" size="mini" v-model="radioItem.name" @input="forceUpdate">
                  </el-input>
                  <el-button icon="el-icon-close" v-if="radioIndex>1" type=" success" plain size="mini" circle
                    @click="delItemsItem(index,radioIndex)"></el-button>
                </div>
              </el-form-item>
            </div>
            <el-button size="mini" icon="el-icon-plus" circle @click="appendItemsItem(index)">
            </el-button>
          </div>
        </div>
        <!-- 下拉选择 -->
        <div v-else-if="page_data.configList.type=='select'">
          <div>
            <div v-for="(radioItem,radioIndex) in page_data.configList.items" :key="index+'radio'+radioIndex">
              <el-form-item :prop="`configList.items.${radioIndex}.name`" :rules="rules.notNull"
                :label="`选项${radioIndex+1}`">
                <div class="flex-center">
                  <el-input placeholder="请输入选项名称" size="mini" v-model="radioItem.name" @input="forceUpdate">
                  </el-input>
                  <el-button icon="el-icon-close" v-show="radioIndex>1" type=" success" plain size="mini" circle
                    @click="delItemsItem(index,radioIndex)"></el-button>
                </div>
              </el-form-item>
            </div>
            <el-button class=" m-t-10" size="mini" icon="el-icon-plus" circle @click="appendItemsItem(index)">
            </el-button>
          </div>
        </div>
        <!-- 上传 -->
        <div v-else-if="page_data.configList.type=='upload'">
          <div>
            <el-form-item :prop="`configList.uploadType`" label="文件常用类型" :rules="rules.notNull">
              <el-select v-model="page_data.configList.uploadType" size="mini" :value="page_data.configList.uploadType"
                @change="selectType">
                <el-option label="图片" value="img">图片</el-option>
                <el-option label="视频" value="video">视频</el-option>
                <el-option label="文档" value="word">文档</el-option>
                <el-option label="表格" value="excel">表格</el-option>
                <el-option label="压缩包" value="zip">压缩包</el-option>
              </el-select>
            </el-form-item>
            <el-form-item :prop="`configList.count`" label="最大上传数">
              <el-tooltip class="item" effect="light" content="上传数量" placement="top">
                <el-input-number :min="1" :step="1" v-model="page_data.configList.count" size="mini"
                  @change="forceUpdate"></el-input-number>
              </el-tooltip>
            </el-form-item>
            <el-form-item :prop="`configList.size`" label="文件大小限制">
              <el-tooltip class="item" effect="light" content="限制上传文件大小,单位:MB" placement="top">
                <el-input-number v-model="page_data.configList.size" size="mini">
                </el-input-number>
              </el-tooltip>
            </el-form-item>
          </div>
          <div class="m-t-10">
            <div style="display: flex;flex-wrap: wrap;">
            </div>
            <el-dialog v-model="dialogVisible">
              <img width="100%" :src="dialogImageUrl" alt="">
            </el-dialog>
          </div>
        </div>
        <!-- 多级 -->
        <div v-else-if="page_data.configList.type=='cascader'">
          <el-form-item :prop="`configList.dataType`" :rules="rules.notNull" label="选择类型">
            <el-select size="mini" v-model="page_data.configList.dataTypeName" @change="selectDataType">
              <el-option v-for="(option,opindex) in cascaderDataTypeList" :value="opindex+','+index"
                :label="option.label" :disabled="option.disabled" :key="index+'dataType'+opindex"></el-option>
            </el-select>
          </el-form-item>
          <div v-if="page_data.configList.dataType=='custom'">
            <el-button icon="el-icon-plus" size="mini" type="primary" plain @click="appendParentNode(index)">添加节点
            </el-button>
            <el-tree :data="page_data.configList.options" default-expand-all :expand-on-click-node="false"
              v-if="showtree" :props="defaultProps" @node-click="handleNodeClick">
              <template class="custom-tree-node" #default="{ node, data }">
                <span @dblclick="data.editing = true" v-if="data.editing==false">
                  {{data.label}}
                </span>
                <span v-else>
                  <el-input v-model="data.label" @input="forceUpdate" size="mini">
                  </el-input>
                </span>
                <span style="width:100%">
                  <!-- <icon-font type="icon-a-zu1750" @click="data.editing = true" class="m-l-10" style="font-size:16px"></icon-font> -->
                  <!-- <icon-font type="icon-tianjia" @click="appendNode(index,node,data)" class="m-l-10" style="font-size:16px" ></icon-font> -->
                  <!-- <icon-font type="icon-shibai2x" @click="delNode(index,node,data)" class="m-l-10" style="color:firebrick;font-size:16px"></icon-font> -->

                  <el-button size="mini" type="text" style="color: firebrick;" class="m-l-10 fr"
                    @click="delNode(index,node,data)">
                    删除</el-button>
                  <el-button size="mini" type="text" class="m-l-10 fr" @click="appendNode(index,node,data)">添加
                  </el-button>
                  <el-button size="mini" type="text" class="m-l-40 fr"
                    :style="data.editing?'color:rgb(99，35,255);':'color:#afa;'" @click="data.editing = !data.editing ">
                    {{data.editing?'保存':'修改'}}</el-button>
                </span>
              </template>
            </el-tree>
          </div>
        </div>
      </el-form>
      <div class="flex-center">
        <el-button class="cancel" @click="cancel">取消</el-button>
        <el-button class="comfire" @click="check">确认{{editid==-1?'添加':'修改'}}</el-button>
      </div>
    </div>
  </div>
</template>

<script>
// import axios from 'axios'
import deepClone from '@/utils/deepClone'
import {
  createForm,
  queryFormTemplate,
  updateForm,
} from '@/api/config/config.ts'
// import Mock  from 'mockjs'
export default {
  data() {
    return {
      defaultProps: {
        children: 'children',
        label: 'label',
      },
      formOnchoose: '',
      formList: [],
      showtree: true,
      dialogImageUrl: '', //预览图路径
      dialogVisible: false, //预览图是否显示
      rules: {
        //规则
        notNull: {
          //非空
          required: true,
          message: '不能为空',
          index: 1,
          validator: (r, v, c) => {
            let arr = r.field.split('.')
            let final = this.page_data[arr[0]]
            for (let i = 1; i < arr.length; i++) {
              final = final[arr[i]]
            }
            if (final) {
              c()
            } else {
              c(r.message)
              this.$message.warning(r.message)
            }
          },
          trigger: ['blur', 'change'],
        },
      },
      page_data: {
        //表单数据
        formName: '', //表单名称
        remark: '', //上传规则，如果有上传选项会根据上传选项参数设置备注
        configList: {
          //【配置列表
          title: '', //标题
          type: '', //类型
          name: '', //类型中文
          submitKey: '',
          required: 1, //是否必填
        },
      },
      page_item: {
        //【配置列表
        title: '', //标题
        type: '', //类型
        name: '', //类型中文
        submitKey: '',
        required: 1, //是否必填
      },
      dfItem: {
        title: '', //标题
        type: '', //类型
        name: '', //类型中文
        required: 1, //是否必填
        placeholder: '', //占位文本
        textLength: 0, //限制长度  0为不限制
        pattern: '', //正则校验
        items: [], //多选选项
        mulite: 2, //可选多少个
        endWith: '', //文件格式
        uploadType: '', //上传类型
        count: 1, //上传数量
        size: 1, //大小限制  填0不做限制
        dataType: '', //级联选择数据类型
        dataTypeName: '', //级联选择名称
        options: [], //参数
      },
      typeList: [
        {
          name: '所属行业',
          type: 'industry',
          items: [
            {
              //选项列表
              name: '',
            },
            {
              name: '',
            },
          ],
          defaultVal: null, //默认值
        },
        {
          //种类
          name: '输入框',
          type: 'input',
          placeholder: '', //占位文本
          textLength: 0, //限制长度  0为不限制
          pattern: '', //正则校验
          patternName: '', //正则校验
          defaultVal: '', //默认值
        },
        {
          name: '文本域',
          type: 'textarea',
          placeholder: '',
          defaultVal: '', //默认值
        },
        {
          name: '电话文本域',
          type: 'mobileTextarea',
          placeholder: '',
          defaultVal: '', //默认值
        },
        {
          name: '滑块',
          type: 'switch',
          defaultVal: false,
        },
        {
          name: '单选',
          type: 'radio',
          items: [
            {
              //选项列表
              name: '',
            },
            {
              name: '',
            },
          ],
          defaultVal: null, //默认值
        },
        {
          name: '多选',
          type: 'checkbox',
          items: [
            {
              name: '',
            },
            {
              name: '',
            },
          ],
          mulite: 2, //可选多少个
          defaultVal: [],
        },
        {
          name: '下拉框',
          type: 'select',
          items: [
            {
              name: '',
            },
            {
              name: '',
            },
          ],
          defaultVal: null,
        },
        {
          name: '上传',
          type: 'upload',
          endWith: '',
          uploadType: '',
          size: 1, //大小限制  填0不做限制
          defaultVal: [],
          // templateImage: '',
          // example: '',
          count: 1,
          // lessCount: 1,
        },
        {
          name: '日期选择',
          type: 'date',
          defaultVal: '',
        },
        {
          name: '级联选择',
          type: 'cascader',
          defaultVal: [],
          dataType: '',
          dataTypeName: '',
          options: [],
        },
      ],
      cascaderDataTypeList: [
        {
          value: 'custom',
          label: '定制',
          disabled: false,
        },
        {
          value: 'provinces',
          label: '省市区选择',
          disabled: true,
        },
      ],
      usefullPattern: [
        {
          //常用正则
          name: '手机号',
          pattern: '^1[3456789]\\d{9}$',
        },
        {
          name: '邮箱',
          pattern: '^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(.[a-zA-Z0-9_-]+)+$',
        },
        {
          name: '身份证',
          pattern:
            '^[1-9]\\d{5}(18|19|20|(3\\d))\\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\\d{3}[0-9Xx]$',
        },
      ],
      iframeSrc: `/#/configModals/preview?config=${JSON.stringify(
        this.page_data
      )}`,
      indexMap: null,
      editid: -1,
      loading: false,
    }
  },
  mounted() {
    // console.log(this.$route.path);
    // fetch('http://192.168.2.135:7001/provinces')
    // this.formList = JSON.parse(localStorage.getItem('formlist')) || []
    // if (this.formList) {
    //   console.log(this.formList)
    //   this.formList = this.formList.map((e) => unescape(e))
    // }
    this.indexMap = new Map()
    this.typeList.forEach((e) => {
      this.indexMap.set(e.type, 0)
    })

    if (this.$route.query.id) {
      this.editid = this.$route.query.id
      queryFormTemplate(this.$route.query.id).then((res) => {
        if (res.code == 200) {
          this.page_data.formName = res.data.formName
          this.page_data.remark = res.data.uploadRule
          this.page_data.configList = JSON.parse(res.data.formContext)
        }
      })
    }
    // queryFormList().then(res=>{
    //   if(res.code == 0){
    //     console.log(res.data)
    //   }
    // })
    // console.log(this.typeList.map(e=>e.type))
  },
  methods: {
    patternUpdate() {
      this.page_data.configList.patternName = '自定义'
      this.forceUpdate()
    },
    patternNameUpdate(e) {
      this.page_data.configList.pattern = e
      this.forceUpdate()
    },
    appendParentNode(index) {
      // console.log(index);
      this.page_data.configList.options.push({
        id: `0-${this.page_data.configList.options.length}`,
        label: '第1层',
        children: [],
        editing: false,
      })
      // console.log(this.page_data.configList[index].options);
      // this.$forceUpdate();
      this.showtree = false
      setTimeout(() => {
        this.showtree = true
      }, 10)
    },
    appendNode(index, node, data) {
      console.log(data.id, data.label, data.children)
      // let arr = data.id.split('-')

      data.children.push({
        id: this.getDeep(node) + '-' + data.children.length,
        label: `第${this.getDeep(node) + 1}层`,
        children: [],
        editing: false,
      })
      // console.log(this.page_data.configList[index].options);
      this.showtree = false
      setTimeout(() => {
        this.showtree = true
      }, 10)
    },
    delNode(index, node, data) {
      let parent = node.parent.data
      // console.log({parent});
      let nodeIndex = 0
      if (parent.children) {
        nodeIndex = parent.children.findIndex((e) => e.id == data.id)
        parent.children.splice(nodeIndex, 1)
      } else {
        nodeIndex = parent.findIndex((e) => e.id == data.id)
        parent.splice(nodeIndex, 1)
      }
      this.showtree = false
      setTimeout(() => {
        this.showtree = true
      }, 10)
    },
    getDeep(node) {
      let n = node
      let length = 0
      while (n.parent != null) {
        length++
        n = n.parent
      }
      console.log(length)
      return length
    },
    handleNodeClick(data) {
      console.log(data, this.page_data.configList.options)
    },
    getLocalStorage() {
      if (!this.formOnchoose) {
        return this.$notify.warning({
          message: '请选择表单',
        })
      }
      this.page_data = JSON.parse(
        localStorage.getItem(escape(this.formOnchoose))
      )
    },
    handlePictureCardDelete(index) {
      this.page_data.configList.templateImage = ''
      this.$forceUpdate()
    },
    handlePictureCardDeleteExample(index) {
      this.page_data.configList.example = ''
      this.$forceUpdate()
    },
    handlePictureCardPreview(url) {
      this.dialogImageUrl = url
      this.dialogVisible = true
    },
    handleRemove(file) {
      let index = this.$refs['upload11'][0].uploadFiles.findIndex(
        (e) => e.url == file.url
      )
      this.$refs['upload11'][0].uploadFiles.splice(index, 1)
    },
    handleOnChange(f, fl) {
      console.log('handleOnChange', f, fl)
    },
    handleBeforeUpload(file) {
      console.log(file)
    },
    handleUpload(event) {
      // console.log(event);
      let index = parseInt(event.filename.split('.')[1])
      let form = new FormData()
      form.append('file', event.file)
      let loading = this.$loading({
        mask: true,
        text: '正在上传中',
      })
      let timeOutId = setTimeout(() => {
        if (loading) {
          loading.close()
          loading = this.$loading({
            mask: true,
            text: '正在上传中,请稍等。上传文件过大,网速慢,同时上传人数多会导致上传速度慢',
          })
        }
      }, 5000)
      fetch('http://192.168.2.135:7001/uploadImg', {
        method: 'post',
        body: form,
      })
        .then((response) => {
          if (response.ok) {
            return response.json()
          } else {
            throw new Error(response.statusText)
          }
        })
        .then((res) => {
          loading.close()
          loading = null
          clearTimeout(timeOutId)
          this.page_data.configList.templateImage = res.url
          this.$forceUpdate()
        })
        .catch((err) => {
          loading.close()
          loading = null
          console.log(err)
        })
    },
    handleUploadExample(event) {
      // console.log(event);
      let index = parseInt(event.filename.split('.')[1])
      let form = new FormData()
      form.append('file', event.file)
      let loading = this.$loading({
        mask: true,
        text: '正在上传中',
      })
      let timeOutId = setTimeout(() => {
        if (loading) {
          loading.close()
          loading = this.$loading({
            mask: true,
            text: '正在上传中,请稍等。上传文件过大,网速慢,同时上传人数多会导致上传速度慢',
          })
        }
      }, 5000)
      fetch('http://192.168.2.135:7001/uploadImg', {
        method: 'post',
        body: form,
      })
        .then((response) => {
          if (response.ok) {
            return response.json()
          } else {
            throw new Error(response.statusText)
          }
        })
        .then((res) => {
          loading.close()
          loading = null
          clearTimeout(timeOutId)
          this.page_data.configList.example = res.url
          this.$forceUpdate()
        })
        .catch((err) => {
          loading.close()
          loading = null
          console.log(err)
        })
    },
    // //向下一个
    // toLower(index) {
    //   let temp = this.page_data.configList[index + 1]
    //   this.page_data.configList[index + 1] = this.page_data.configList[index]
    //   this.page_data.configList[index] = temp
    //   this.$forceUpdate()
    // },
    // //向上一个
    // toUper(index) {
    //   let temp = this.page_data.configList[index - 1]
    //   this.page_data.configList[index - 1] = this.page_data.configList[index]
    //   this.page_data.configList[index] = temp
    //   this.$forceUpdate()
    // }, //去预览
    preview() {
      if (!this.formOnchoose) {
        return this.$notify.error({
          message: '未选择表单',
        })
      }
      this.$router.push({
        name: 'preview',
        query: {
          form: escape(this.formOnchoose),
        },
      })
    },
    log() {
      console.log(this.page_data.configList)
    },
    cancel() {
      this.$router.push({ path: '/line/SetLine', query: { reline: 1 } })
    },
    check() {
      //校验
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.$notify.success({
            message: '校验通过',
          })
          // let key = escape(this.page_data.formName)
          // this.formList.push(this.page_data.formName)
          // localStorage.setItem(key, JSON.stringify(this.page_data))
          // let list = JSON.parse(localStorage.getItem('formlist'))
          // if (list) {
          //   if (!~list.findIndex((e) => e == key)) {
          //     list.push(key)
          //   }
          //   localStorage.setItem('formlist', JSON.stringify(list))
          // } else {
          //   localStorage.setItem('formlist', JSON.stringify([key]))
          // }
          // console.log(JSON.stringify(this.page_data.configList))
          // console.log(this.page_data)
          // console.log(JSON.stringify(this.page_data))

          if (this.page_data.configList.type == 'upload') {
            if (this.page_data.configList.uploadType == 'zip') {
              this.page_data.remark = `上传个数${this.page_data.configList.count}个，大小不超过${this.page_data.configList.size}M。文件上传格式${this.page_data.configList.endWith}`
            } else {
              this.page_data.remark = `上传图片张数${this.page_data.configList.count}张，大小不超过${this.page_data.configList.size}M。文件上传格式${this.page_data.configList.endWith}`
            }
          } else {
            this.page_data.remark = ''
          }
          let param = {
            formContext: JSON.stringify(this.page_data.configList),
            formName: this.page_data.formName,
            uploadRule: this.page_data.remark,
            required: this.page_data.configList.required,
            formType:
              this.page_data.configList.type == 'upload'
                ? this.page_data.configList.uploadType
                : this.page_data.configList.type,
          }

          // let esName = escape(this.page_data.formName);
          // let list = localStorage['formlist']||'[]';
          // list = JSON.parse(list);
          // if(!~list.findIndex(e=>e==esName)){
          //   list.push(esName)
          // }
          // localStorage['formlist'] = JSON.stringify(list);
          // localStorage[esName] = JSON.stringify(param);
          this.loading = true
          if (this.editid != -1) {
            param.id = parseInt(this.editid)
            updateForm(param)
              .then((res) => {
                this.loading = false
                this.$notify.success({
                  message: '修改成功',
                })
                this.$router.go(0)
              })
              .catch((err) => {
                this.loading = false
              })
          } else {
            createForm(param)
              .then((res) => {
                this.loading = false
                if (res.code == 200) {
                  this.$notify.success({
                    message: '添加成功',
                  })
                  // this.$router.go(0)
                  this.page_data.formName = ''
                  this.page_data.configList = deepClone(this.page_item)
                  this.$refs['form'].resetFields()
                  // // delete this.typeList[selectIndex][this.typeList[selectIndex].type + 'Value']
                  // this.$forceUpdate()
                }
              })
              .catch((err) => {
                this.loading = false
              })
          }
        }
      })
    },
    selectType(v) {
      // let arr = v.split('.')
      let ends = ''

      switch (v) {
        case 'img':
          ends = 'jpg,jpeg,png,bmp'
          // type = 'img'
          break
        case 'video':
          ends = 'mp4,rmvb,avi'
          // type = 'video'
          break
        case 'word':
          ends = 'doc,docx'
          // type = 'word'
          break
        case 'excel':
          ends = 'xls,xlsx,csv'
          // type = 'excel'
          break
        case 'zip':
          ends = '.zip'
          // type = 'excel'
          break
        default:
          break
      }
      // this.page_data.configList.uploadType = type
      this.page_data.configList.endWith = ends
      this.$nextTick(this.forceUpdate)
    },
    selectDataType(v) {
      let arr = v.split(',')
      let selectIndex = parseInt(arr[0])
      let onIndex = parseInt(arr[1])

      this.page_data.configList.dataType =
        this.cascaderDataTypeList[selectIndex].value
      this.page_data.configList.dataTypeName =
        this.cascaderDataTypeList[selectIndex].label
      this.$forceUpdate()
    },
    forceUpdate() {
      this.$forceUpdate()
    },
    test(index) {
      console.log(this.page_data.configList.mulite)
    },
    delItemsItem(index, itemIndex) {
      if (this.page_data.configList.items.length > 1) {
        this.page_data.configList.items.splice(itemIndex, 1)
        if (
          this.page_data.configList.mulite >
          this.page_data.configList.items.length
        ) {
          this.page_data.configList.mulite =
            this.page_data.configList.items.length
        }
        this.$forceUpdate()
      } else {
        this.$notify.warning({
          message: '至少保留一个',
        })
      }
    },
    //添加选项
    appendItemsItem(index) {
      this.page_data.configList.items.push({
        name: '',
      })
      this.$forceUpdate()
    },
    delByIndex(index) {
      this.page_data.configList.splice(index, 1)
    },
    select(index) {
      let arr = index.split(',')
      let selectIndex = parseInt(arr[0])
      let onIndex = parseInt(arr[1])
      let { title, required } = this.page_data.configList
      let type = this.typeList[selectIndex].type
      // let value = this.indexMap.get(type)
      let obj = {
        title,
        required,
        submitKey: type + Date.now(),
        // this.typeList[selectIndex].type+this.page_data.configList.length,
      }
      if (type == 'mobileTextarea') {
        obj.submitKey = 'mobiles'
      }
      if (type == 'industry') {
        obj.submitKey = 'industry'
      }
      // this.indexMap.set(type, value + 1)
      // console.log(type, value)
      // obj[obj.submitKey] = this.typeList[selectIndex].defaultVal
      // this.typeList[selectIndex][this.typeList[selectIndex].type + 'Value']
      this.page_data.configList = Object.assign(
        {},
        deepClone(this.typeList[selectIndex]),
        obj
      )
      // delete this.typeList[selectIndex][this.typeList[selectIndex].type + 'Value']
      this.$forceUpdate()
    },
  },
}
</script>

<style lang="scss" scoped>
.fr {
  float: right;
}
::v-deep(.el-form-item .el-form-item) {
  margin: 0 !important;
}
::v-deep(.el-input__inner) {
  border: 1px solid #dcdfe6;
}
@for $i from 1 through 40 {
  .m-#{$i} {
    margin: $i + px;
  }
  .m-t-#{$i} {
    margin-top: $i + px;
  }
  .m-b-#{$i} {
    margin-bottom: $i + px;
  }
  .m-l-#{$i} {
    margin-left: $i + px;
  }
  .m-r-#{$i} {
    margin-right: $i + px;
  }

  .p-#{$i} {
    padding: $i + px;
  }
  .p-t-#{$i} {
    padding-top: $i + px;
  }
  .p-b-#{$i} {
    padding-bottom: $i + px;
  }
  .p-l-#{$i} {
    padding-left: $i + px;
  }
  .p-r-#{$i} {
    padding-right: $i + px;
  }

  .fz-#{$i} {
    font-size: $i + px;
  }
}

.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}

.bigcontext {
  padding: 10px !important;
  text-align: start !important;
}

.mcontext {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.flex-center {
  display: flex;
  align-items: center;
  &.mt {
    margin-top: 18px;
  }
}

.fixrighttop {
  position: fixed;
  top: 0;
  right: 0;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 128px;
  height: 128px;
  line-height: 128px;
  text-align: center;
  border: 2px dashed;
  border-radius: 0.5rem;

  &:hover {
    border-color: #409eff;
  }

  &.r {
    position: relative;

    input {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
    }
  }
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.abshover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;

  &:hover {
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.3);
  }

  [class*='el-icon'] {
    color: #fff;
    cursor: pointer;
  }
}

.cancel {
  width: 80px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #c7c7c7;
  border-radius: 4px;
  font-size: 14px;
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: 400;
  text-align: left;
  color: #999999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.comfire {
  width: 108px;
  height: 32px;
  background: #637dff;
  border-radius: 4px;
  font-size: 14px;
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: 400;
  text-align: left;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imgcontext {
  width: 128px;
  height: 128px;
  position: relative;
  border: 1px dashed gray;
  border-radius: 0.5rem;
  overflow: hidden;
}

*::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 4px;
  /*高宽分别对应横竖滚动条的尺寸*/
  height: 4px;
  scrollbar-arrow-color: red;
}

*::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2);
  scrollbar-arrow-color: red;
}

*::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 0;
  background: rgba(0, 0, 0, 0.1);
}
</style>
